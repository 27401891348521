import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Grid, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import useProjects from './firestore/Projects';
import useAuth from './AuthUser';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
let resolve = undefined;

export default function useAnalyticsIntegrationDialog() {
    const [open, setOpen] = React.useState(false);
    const [projectName, setProjectName] = React.useState();
    const [userId, setUserId] = React.useState();

    const { user } = useAuth();

    const [propertyId, setPropertyId] = React.useState();

    const { register, handleSubmit, formState: { errors } } = useForm();

    const { updatePropertyId } = useProjects();



    const handleClose = (result = false) => {
        setOpen(false);
        resolve(result);
    };


    const dialog = React.useMemo(() => {
        return (
            <div>
                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => handleClose(false)}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle>Analytics Integration Dialog</DialogTitle>
                    <DialogContent>
                        <DialogContentText variant='body1' textAlign={'justify'} id="alert-dialog-slide-description">
                            Hey, if you are seeing this dialog, that's means your analytics integration is not fixed yet. But don't worry, it is an simple process which is be done by you. Please watch the below video to complete the process.
                            <br />
                            <a rel="noreferrer" href='https://drive.google.com/file/d/1AIUlUy3n4C0LxevcbfbQjdWWkdMpltI9/view?usp=sharing' target={"_blank"} className="text-primary" >Click here to watch the video</a>
                        </DialogContentText>
                        <Grid container>
                            <Grid item xs={12}>
                                <TextField
                                    margin="normal"
                                    helperText={errors?.propertyId?.message ?? "Enter Property ID of your firebase console analytics integration"}
                                    error={errors?.propertyId}
                                    fullWidth
                                    name="propertyId"
                                    color='primary'
                                    label="Propert ID"
                                    {...register("propertyId", { required: 'This field is required' })}
                                    type="text"
                                    value={propertyId}
                                    id="propertyId"
                                    onChange={(e) => {
                                        setPropertyId(e.target.value);
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button color='secondary' onClick={() => handleClose(false)}>Close</Button>
                        <Button color='secondary' onClick={handleSubmit(async () => {
                            await updatePropertyId(propertyId, projectName, userId);
                            handleClose(true);
                        })}>DONE</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }, [open, register, handleSubmit, propertyId, updatePropertyId, errors?.propertyId, projectName, userId]);

    return [
        dialog,
        async (_projectName, _userId = user.uid) => {
            setOpen(false);
            setProjectName(_projectName);
            setUserId(_userId);
            setTimeout(() => setOpen(true), 300);
            return new Promise((_resolve, _) => {
                resolve = _resolve;
            });
        }
    ];
}